import RetailerConfig from "./retailer";
import queryString from "query-string";


const hostname = window.location.hostname;
const isStaging = hostname.includes('cloudfront');
const isLocalhost = hostname.includes('localhost');
const useTest = isLocalhost || isStaging;
const sub = useTest ? "test." : "";

const parsed = queryString.parse(window.location.search);
const config = {
  myEnv: isLocalhost ? 'local' : 'production',
  retailer: RetailerConfig,
  baseUrl: parsed.testing ? "" : useTest ? "https://ice.test.dpn.inmar.com/v2/" : "https://ice.dpn.inmar.com/v2/",
  originUrl: isLocalhost ? `${window.location.origin}/` : "",
  urlParam: parsed
};
export default Object.freeze(config);
