import React, { useEffect, useState, useContext } from 'react';
import CartContentItem from './item';
import { SiteConfigContext } from '../SiteConfigContext';

import './content.scss';

//display add-to-cart section
function CartContent(props) {
  const siteConfig = useContext(SiteConfigContext);
  console.log(siteConfig.components)
  const block = siteConfig.components.find(component => component.block_type === 'AddToCartBlock');
  const data = block.addtocart_set;
  let a2cHeader = block.a2c_header;
  if (data.a2cHeader)
  {
    a2cHeader = data.a2cHeader;
  }

  return (
    <>
      {(data.length > 0) && (
        <div className="content-page">
          {a2cHeader && <h2 className='sectionTitle'>{a2cHeader}</h2>}
          <div className="cart-content">
            {data.map((item, i) => {
              return <CartContentItem item={item} key={i} />;
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default CartContent;
