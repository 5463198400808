import React, { useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';
import './FreeText.scss'; // Import your CSS file

function About() {
  const siteConfig = useContext(SiteConfigContext);
  const block = siteConfig.components.find(component => component.block_type === 'FreeTextBlock');
  const { title, column_1, column_2, column_3 } = block;

  const renderColumns = () => {
    const columns = [];

    if (column_1) {
      columns.push(<div dangerouslySetInnerHTML={{ __html: column_1 }} />);
    }
    if (column_2) {
      columns.push(<div dangerouslySetInnerHTML={{ __html: column_2 }} />);
    }
    if (column_3) {
      columns.push(<div dangerouslySetInnerHTML={{ __html: column_3 }} />);
    }

    return columns.map((column, index) => (
      <div key={index} className={`column column-${index + 1}`}>
        {column}
      </div>
    ));
  };

  return (
    <div className="free-text-block">
      {title && <h2 className='free-text-title'>{title}</h2>}
      <div className="columns-container">
        {renderColumns()}
      </div>
    </div>
  );
};

export default About;
