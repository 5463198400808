import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from '../common/FontAwesome';
import { SiteConfigContext } from '../SiteConfigContext';
import './recipe.scss';

function RecipeContent() {
  const siteConfig = useContext(SiteConfigContext);
  const recipeStyles = 'recipe-content-page default';
  const recipeBlock = siteConfig.components.find(
    (component) => component.block_type === 'RecipeBlock'
  );

  if (!recipeBlock || !recipeBlock.recipes || recipeBlock.recipes.length === 0) {
    return null;
  }

  const recipeCardBackgroundColor = recipeBlock.recipe_card_background_color;
  const recipeCardTextColor = recipeBlock.recipe_card_text_color;
  const recipeViewButtonBackgroundColor = recipeBlock.recipe_view_button_background_color;
  const recipeViewButtonTextColor = recipeBlock.recipe_view_button_text_color;
  const recipeButtonA2CTextColor = recipeBlock.recipe_button_a2c_text_color;
  const recipeButtonA2CBackgroundColor = recipeBlock.recipe_button_a2c_background_color;

  function RecipeItem({ recipe }) {
    const {
      title,
      card_text,
      card_image,
      a2c,
      slug,
      recipe_detail_show_time_headers,
    } = recipe;

    function trackAddToCartEvent() {
      window.gtag('event', 'addtocartclick', {
        product: title,
      });
    }

    function trackGetRecipe() {
      window.gtag('event', 'viewrecipe', {
        recipe: title,
      });
    }

    let recipe_basepath;

    if (process.env.REACT_APP_PREVIEW_MODE === 'true') {
      recipe_basepath = `/${siteConfig.siteName}`;
    } else {
      recipe_basepath = '';
    }

    return (
      <div className="recipe-item" style={{ backgroundColor: recipeCardBackgroundColor }}>
        <div className='imageContent'>
          <img src={card_image} className="card-img-top content-img" alt={title} />
          {a2c && (
            <a
              href={a2c}
              onClick={trackAddToCartEvent}
              target="_blank"
              rel="noopener noreferrer"
              className='cart'
              style={{ color: recipeCardTextColor }}
            >
              <FontAwesome type='shopping-cart' />
            </a>
          )}
        </div>
        <div className='textContent' style={{ color: recipeCardTextColor }}>
          <div className='button-items'>
            <button className="item-btn recipe-btn" style={{ backgroundColor: recipeViewButtonBackgroundColor }}>
              <Link to={`${recipe_basepath}/recipes/${slug}`} style={{ color: recipeViewButtonTextColor }}
                onClick={trackGetRecipe}
              >
                View Recipe
              </Link>
            </button>
            {a2c && (
              <button className="item-btn cart-btn" style={{ backgroundColor: recipeButtonA2CBackgroundColor }}>
                <a
                  href={a2c}
                  onClick={trackAddToCartEvent}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: recipeButtonA2CTextColor }}
                >
                  Add to Cart
                </a>
              </button>
            )}
          </div>
          <div className='text-block'>
            <h4>{title}</h4>
            <p>{card_text}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='recipe-container'>
      <div className={recipeStyles} id="recipesection">
        <h2 style={{ textAlign: 'left', marginLeft: 0, color: recipeCardTextColor }}>{recipeBlock.section_title}</h2>
        <div className='recipeArea'>
          <div className="recipe-content">
            <div className="recipe-section">
              <div className='recipeBlock'>
                {recipeBlock.recipes.map((recipe, j) => (
                  <RecipeItem recipe={recipe} key={j} />
                ))}
              </div>
            </div>
            <div className='clearer'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecipeContent;
