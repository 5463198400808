import React, { useState, useRef, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER } from '../redux/reducers/offer';
import { SET_USER } from '../redux/reducers/user';
import { SiteConfigContext } from '../SiteConfigContext';
import ErrorModal from '../errorModal';
import "./loyalty.scss";
import ProgressBlock from '../progress';

function LoyaltyBlock() {
  const siteConfig = useContext(SiteConfigContext);
  const block = siteConfig.components.find(component => component.block_type === 'LoyaltyBlock');
  const metaBlock = siteConfig.components.find(component => component.block_type === 'MetaDataBlock');

  const enrollment = useSelector((state) => state.user.enrollment);
  const user = useSelector((state) => state.user.username);

  const dispatch = useDispatch();
  const {
    config: {
      retailer: { id: retailerId },
    },
  } = useSelector((state) => state);

  const [enrollmentButtonClicked, setEnrollmentButtonClicked] = useState(false);
  const [validationSuccessful, setValidationSuccessful] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showError, setShowError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();

  const funnel = metaBlock.funnel || 'n/a';
  const channel = metaBlock.channel || 'n/a';
  const qualifying_offer_id = block.qualifying_offer_id;

  useEffect(() => {
    if (validationSuccessful && enrollmentButtonClicked) {
      trackEnrollmentEvent();
    } else if (validationSuccessful) {
      trackLoginEvent();
    }
  }, [validationSuccessful, enrollmentButtonClicked]);

  const handleClose = () => {
    dispatch(SHOW_LOGIN({ show: false }));
  };

  const handleErrorClose = () => {
    setShowError(false);
  };

  async function enrollEmail(email, email_debug = false) {
    const enroll_lambda_url = "https://badbfyk6uqlormgdxkgwbchlfq0pvulw.lambda-url.us-east-1.on.aws/";
    const trigger_lambda_url = "https://oivrtokwaa4uqgdrictn27vm5y0znfae.lambda-url.us-east-1.on.aws/";

    try {
      const response1 = await fetch(enroll_lambda_url + '?email=' + email);
      const data1 = await response1.json();
      console.log(data1.status_message);
      if (data1.status_code === 'OK' || email_debug) {
        console.log('user successfully enrolled or debug enabled, attempt to send notification email');
        const response2 = await fetch(trigger_lambda_url + '?email=' + email);
        const data2 = await response2.json();
        console.log(data2.status_message);
      }
      if (data1.status_message === 'The subscriber is already on the list') {
        console.log('The subscriber is already on the list, not sending email');
      }
    } catch (error) {
      console.error(`Error enrolling email: ${email}`, error);
    }
  }

  function checkEmailValidation(e) {
    const emailInput = e.target.value;
    const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (emailInput === '' && phoneNumber === '') {
      setLoginError(false);
      setIsDisabled(true);
    } else if (emailInput === '') {
      setLoginError('Email is required');
      setIsDisabled(true);
    } else if (emailRegexp.test(emailInput)) {
      setLoginError(false);
      setEmail(emailInput);
      console.log(emailInput)
      console.log(email)

      if(phoneNumber && phoneNumber.match(/^[0-9]{10}$/)) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setLoginError('Please enter a valid email address');
      setIsDisabled(true);
    }
  }

  function checkPhoneNumberValidation(e) {
    const phoneNumberInput = e.target.value;
    const phoneRegexp = /^(\d{10}|\d{3}[-\s]\d{3}[-\s]\d{4}|\(\d{3}\)[-.\s]\d{3}[-.\s]\d{4}|\d{3}[-\s]\d{4})$/;

    if (phoneNumberInput === '' && email === '') {
      setLoginError(false);
      setIsDisabled(true);
    } else if (phoneNumberInput === '') {
      setLoginError('Phone number is required');
      setIsDisabled(true);
    } else if (phoneRegexp.test(phoneNumberInput)) {
      setLoginError(false);
      setPhoneNumber(phoneNumberInput);

      if(email && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setLoginError('Phone number must be 10 digits');
      setIsDisabled(true);
    }
  }

  async function checkUserExistence(id, phoneNumber, email) {
    let baseUrl = ""
    if (window.location.hostname === 'localhost' || window.location.hostname ==='cloudfront.net') {
      // Code for when running on CloudFront
      baseUrl = "https://ice.test.dpn.inmar.com/v2/offers/load";
  } else {
      // Code for other environments
      baseUrl = "https://ice.test.dpn.inmar.com/v2/offers/load";
  }
    

    const options = {
      headers: {
        'Content-Type': 'application/json',
        'X-Retailer': 'publix'
      }
    };
    const phoneUrl = `${baseUrl}?username=${encodeURIComponent(phoneNumber)}&id=${encodeURIComponent(id)}&channel=Eventsite&usernameType=phone`;
    const emailUrl = `${baseUrl}?username=${encodeURIComponent(email)}&id=${encodeURIComponent(id)}&channel=Eventsite&usernameType=email`;

    const phoneResponse = await fetch(phoneUrl, options);
    const emailResponse = await fetch(emailUrl, options);

    const phoneResult = await phoneResponse.json();
    const emailResult = await emailResponse.json();

    return phoneResult.status !== 404 && emailResult.status !== 404;
  }

  function handleValidation() {
    checkUserExistence(qualifying_offer_id, phoneNumber, email).then(userExists => {
      if (phoneNumber && userExists) {
        const options = {
          is_qualifying_offer: true,
          endpoint: 'offers/load',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-retailer': retailerId,
            Origin: window.location.origin,
          },
          body: { 
            username: phoneNumber, 
            captchaToken: "22",
            id: qualifying_offer_id,
            channel: channel, 
            funnel: funnel,
          },
        };

        enrollEmail(email, false)
          .then(() => {
            console.log('Email enrollment complete');
          })
          .catch((error) => {
            console.log('Error enrolling email', error);
          });

        dispatch(CLIP_OFFER(options)).then((resp) => {
          if (resp.payload.success) {
            setValidationSuccessful(true);
            dispatch(SET_USER({ state: 2, username: phoneNumber, enrollment: true }));
            saveToStorage(phoneNumber, JSON.stringify(resp.payload.mdid));
            handleClose();
          } else {
            setEnrollmentError(resp.payload.errorMsg);
            setShowError(true);
          }
        });
      } else {
        setEnrollmentError('Phone number or email not found.');
        setShowError(true);
        trackEnrollmentError();
      }
    })
  }

  function saveToStorage(username, mdid) {
    const obj = { username: username, myIds: mdid };
    localStorage.setItem('pbyt', JSON.stringify(obj));
  }

  function RenderLoginError() {
    if (loginError) {
      return <div className="error text-danger">{loginError}</div>;
    }
    return '';
  }

  function trackLoginEvent() {
    window.gtag('event', 'account_login', { retailer_id: "Publix" });
    console.log("tracked login event");
  }

  function trackEnrollmentEvent() {
    console.log('Enrollment event tracked');
    window.gtag('event', 'enrollment', { retailer_id: "Publix" });
  }

  function trackEnrollmentError() {
    console.log('track enrollment error event');
    window.gtag('event', 'enroll_error', {});
  }

  function trackRegistrationEvent() {
    window.gtag('event', 'retailer_registration_click', {});
    console.log('registration click event tracked');
  }
  if (!enrollment) {
    return (
      <>
      <div id='enrollment-container'>
        <div className='enrollment-offer-container' style={{ backgroundColor: block.color_background_1 }}>
          <h1 style={{ color: block.color_title }}>{block.enrollment_title}</h1>
        </div>
        <div className="login-container" style={{ backgroundColor: block.color_background_2 }}>
          <h2 style={{ color: block.color_header }}>{block.enrollment_header}</h2>
          <div className="enrollment-login-content-container">
            <form>
              <label htmlFor="phone" style={{ color: block.color_form_field_headers }}>{block.enroll_credential_1}<span>*</span></label>
              <input
                type="text"
                onChange={(e) => { checkPhoneNumberValidation(e); }}
                placeholder={block.enroll_credential_placeholder_1}
                />

              <label htmlFor="email" style={{ color: block.color_form_field_headers }}>{block.enroll_credential_2}<span>*</span></label>
              <input
                type="text"
                onChange={(e) => { checkEmailValidation(e); }}
                placeholder={block.enroll_credential_placeholder_2}
                />

              <RenderLoginError />
            </form>
            <div id="enroll-or-sign-in-buttons-container">
              <button
                id='enroll-button'
                onClick={() => {
                  setEnrollmentButtonClicked(true);
                  handleValidation();
                }}
                style={{ color: block.color_enroll_button_text, backgroundColor: block.color_enroll_button_background }}
                >
                {block.enroll_button_text}
              </button>
              <button
                id='sign-in-button'
                onClick={handleValidation}
                style={{ backgroundColor: block.color_sign_in_button_background, color: block.color_sign_in_button_text }}
                >
                {block.sign_in_button_text}
              </button>
            </div>
            {showError && <ErrorModal show={showError} handleClose={handleErrorClose} />}
            <div className='enrollment-footer'>
              <div dangerouslySetInnerHTML={{ __html: block.enrollment_below_form }} />
            </div>
          </div>
        </div>
        {showError && <ErrorModal show={showError} handleClose={handleErrorClose} />}
      </div>
      </>
    )
  }
  else {
    return  <ProgressBlock user={user} />;
  }
}

export default LoyaltyBlock;
