import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tesseract from 'tesseract.js';
import image1 from './1.png';

const LoadTesseract = () => {
  useEffect(() => {
    const convertImageToText = async () => {
      try {
        const imageURL = image1;

        const { data: { text } } = await Tesseract.recognize(imageURL, 'eng', {
          logger: m => console.log(m),
        });
        console.log(text);
      } catch (error) {
        console.error('Error converting image to text', error);
      }
    };

    convertImageToText();
  }, []);

  return <></>;
};

export default LoadTesseract;