import React, { useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';
import Hero from '../hero';

function PrivacyPolicy() {
  const siteConfig = useContext(SiteConfigContext);
  const footerBlock = siteConfig.components.find(component => component.block_type === 'FooterBlock');

  return (
    <>
      <Hero />
      <div dangerouslySetInnerHTML={{ __html: footerBlock.link1_page_content }} style={{padding: "5%"}} />
    </>
  )};

export default PrivacyPolicy;
