import React, { useState, useContext } from 'react';
import './informationBanner.scss';
import shop from './shopping-bag.svg';
import gift from './gift.svg';
import repeat from './repeat.svg';
import QualifyingList from '../qualifyingProducts';
import { SiteConfigContext } from '../SiteConfigContext';

function InformationBanner() {
  const siteConfig = useContext(SiteConfigContext);
  const block = siteConfig.components.find(component => component.block_type === 'QualifyingProductsBlock');
  const [showQualifyingProducts, setShowQualifyingProducts] = useState(false);

  function trackQualifyingProductsEvent() {
    console.log('qualifyingProducts event tracked');
    window.gtag('event', 'open_qualifying_products', {});
  }

  const handleQualifyingClose = () => {
    setShowQualifyingProducts(false);
  };

  const handleShowQualifyingProducts = () => {
    setShowQualifyingProducts(true);
    trackQualifyingProductsEvent();
  };

  return (
    <>
      <div className='information-banner-container' style={{ backgroundColor: block.color_background }}>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet" />
        <div className='information-banner-left'>
          <div className='information-banner-heading' style={{ color: block.color_title }}>
            {block.qualifying_products_title}
          </div>
          <div id='view-qualifying-products-button'>
            <button className="custom-button" onClick={handleShowQualifyingProducts} style={{ color: block.color_button_text, backgroundColor: block.color_button_bg }}>
              {block.qualifying_products_button_text}
            </button>
          </div>
        </div>
        <div className='information-banner-right'>
          <div className='information-list-bullet-column'>
            <div className='information-list-bullet'>
              <div className='information-list-bullet-row'>
                <div className="icon-container" style={{ backgroundColor: block.color_icons_background, borderRadius: 50, padding: 10, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                <svg width="22" height="25" viewBox="0 0 22 25" fill={block.color_icons} xmlns="http://www.w3.org/2000/svg">
<path d="M5.75 8V5.75C5.75 2.89062 8.09375 0.5 11 0.5C13.8594 0.5 16.25 2.89062 16.25 5.75V8H19.25C20.4688 8 21.5 9.03125 21.5 10.25V20C21.5 22.4844 19.4844 24.5 17 24.5H5C2.46875 24.5 0.5 22.4844 0.5 20V10.25C0.5 9.03125 1.48438 8 2.75 8H5.75ZM8 8H14V5.75C14 4.10938 12.6406 2.75 11 2.75C9.3125 2.75 8 4.10938 8 5.75V8ZM2.75 10.25V20C2.75 21.2656 3.73438 22.25 5 22.25H17C18.2188 22.25 19.25 21.2656 19.25 20V10.25H16.25V12.875C16.25 13.5312 15.7344 14 15.125 14C14.4688 14 14 13.5312 14 12.875V10.25H8V12.875C8 13.5312 7.48438 14 6.875 14C6.21875 14 5.75 13.5312 5.75 12.875V10.25H2.75Z" fill={block.color_icons}></path>
</svg>
                </div>
                  <div className='information-list-bullet-header' style={{ color: block.color_headers }}>{block.loyalty_process_step_1_heading}</div>
                  <div className='information-list-bullet-description' dangerouslySetInnerHTML={{ __html: block.loyalty_process_step_1 }} />

              </div>
            </div>
            <div className='information-list-bullet'>
              <div className='information-list-bullet-row'>
                <div className="icon-container" style={{ backgroundColor: block.color_icons_background, borderRadius: 50, padding: 10, width: 50, height: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',}}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4.53125L13.125 2.60938C13.875 1.29688 15.2344 0.5 16.7344 0.5H16.875C19.125 0.5 21 2.375 21 4.625C21 5.32812 20.8125 5.9375 20.5312 6.5H21.75C22.9688 6.5 24 7.53125 24 8.75V11.75C24 12.7344 23.3438 13.5781 22.5 13.9062V21.5C22.5 23.1875 21.1406 24.5 19.5 24.5H4.5C2.8125 24.5 1.5 23.1875 1.5 21.5V13.9062C0.609375 13.5781 0 12.7344 0 11.75V8.75C0 7.53125 0.984375 6.5 2.25 6.5H3.42188C3.14062 5.9375 3 5.32812 3 4.625C3 2.375 4.82812 0.5 7.125 0.5H7.21875C8.71875 0.5 10.0781 1.29688 10.8281 2.60938L12 4.53125ZM13.5 8.75V11.75H21.75V8.75H13.5ZM10.5 8.75H2.25V11.75H10.5V8.75ZM10.5 14H3.75V21.5C3.75 21.9219 4.07812 22.25 4.5 22.25H10.5V14ZM13.5 22.25H19.5C19.875 22.25 20.25 21.9219 20.25 21.5V14H13.5V22.25ZM16.875 6.5C17.9062 6.5 18.75 5.70312 18.75 4.625C18.75 3.59375 17.9062 2.75 16.875 2.75H16.7344C16.0312 2.75 15.4219 3.125 15.0469 3.73438L13.4062 6.5H16.875ZM10.5469 6.5L8.90625 3.73438C8.53125 3.125 7.92188 2.75 7.21875 2.75H7.125C6.04688 2.75 5.25 3.59375 5.25 4.625C5.25 5.70312 6.04688 6.5 7.125 6.5H10.5469Z" fill={block.color_icons}/>
</svg>
                </div>

                  <div className='information-list-bullet-header' style={{ color: block.color_headers }}>{block.loyalty_process_step_2_heading}</div>
                  <div className='information-list-bullet-description' dangerouslySetInnerHTML={{ __html: block.loyalty_process_step_2 }} />

              </div>
            </div>
            <div className='information-list-bullet'>
              <div className='information-list-bullet-row'>
                <div className="icon-container" style={{ backgroundColor: block.color_icons_background, borderRadius: 50, padding: 10, width: 50, height: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',}}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.25 6.5C4.92188 6.5 2.25 9.21875 2.25 12.5C2.25 13.1562 1.73438 13.625 1.125 13.625C0.46875 13.625 0 13.1562 0 12.5C0 7.95312 3.65625 4.25 8.25 4.25H14.9531V1.625C14.9531 1.20312 15.1875 0.78125 15.6094 0.59375C16.0312 0.453125 16.5469 0.546875 16.875 0.875L20.625 4.625C21.0469 5.04688 21.0469 5.75 20.625 6.17188L16.875 9.92188C16.5469 10.25 16.0312 10.3438 15.6094 10.2031C15.1875 10.0156 14.9531 9.59375 14.9531 9.125V6.5H8.25ZM22.875 11.375C23.4844 11.375 24 11.8906 24 12.5C24 17.0938 20.2969 20.75 15.75 20.75H9V23.375C9 23.8438 8.71875 24.2656 8.29688 24.4531C7.875 24.5938 7.35938 24.5 7.07812 24.1719L3.28125 20.4219C2.85938 20 2.85938 19.2969 3.28125 18.8281L7.07812 15.0781C7.35938 14.7969 7.875 14.7031 8.29688 14.8438C8.71875 15.0312 9 15.4531 9 15.875V18.5H15.75C19.0312 18.5 21.75 15.8281 21.75 12.5C21.75 11.8906 22.2188 11.375 22.875 11.375Z" fill={block.color_icons}/>
</svg>
                </div>

                  <div className='information-list-bullet-header' style={{ color: block.color_headers }}>{block.loyalty_process_step_3_heading}</div>
                  <div className='information-list-bullet-description' dangerouslySetInnerHTML={{ __html: block.loyalty_process_step_3 }} />

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="divider-section red"></div>
        <div className="divider-section blue"></div>
        <div className="divider-section brown"></div>
        <div className="divider-section green"></div>
        {showQualifyingProducts && <QualifyingList show={showQualifyingProducts} handleClose={handleQualifyingClose} />}
      </div>
    </>
  );
}

export default InformationBanner;
