import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import SitePage from './site';
import { SiteConfigProvider } from './SiteConfigContext';
import axios from 'axios';
import './App.css';
import RecipePage from './recipes/recipe';
import BrandsiteList from './brandsiteList';
import NotFoundPage from './404';
import PrivacyPolicy from './privacyPolicy'
import site_config_json from './site-config.json';

function SiteComponent() {
  const { siteName } = useParams();
  const hostname = window.location.hostname;
  const isLocalhost = hostname.includes('localhost');
  let apiUrl;

  if (isLocalhost && process.env.REACT_APP_USE_LOCALHOST === 'true') {
    apiUrl = 'http://localhost:8000';
  } else {
    apiUrl = process.env.REACT_APP_BACKEND;
  }

  const brandsiteUrl = `${apiUrl}/brandsites/${siteName}` 
  const [siteConfig, setSiteConfig] = useState(null);

  useEffect(() => {
    const fetchSiteConfig = async () => {
      try {
        let data;
        if (process.env.REACT_APP_PREVIEW_MODE === 'true') {
          const response = await axios.get(brandsiteUrl);
          data = response.data;
        } else {
          data = await import('./site-config.json');
        }
  
        const newSiteConfig = {
          ...data,
          ogTitle: data.og_title,
          ogUrl: data.og_url,
          ogDescription: data.og_description,
          ogImage: data.og_image,
          themeColor: data.theme_color,
          inmarAnalyticsId: data.inmar_analytics,
          fbPixel: data.fb_pixel,
          fbValidation: data.fb_domain_verification,
          apiUrl,
          siteName,
          colorBackground: data.color_background,
          colorText: data.color_text,
          colorLinks: data.color_links,
          colorOfferBackground: data.color_offer_background,
          colorOfferButtonsText: data.color_offer_buttons_text,
          colorOfferButtonsBackground: data.color_offer_buttons_background,
          colorButtonA2CText: data.a2c_button_text_color,
          colorButtonA2CBackground: data.a2c_button_background_color,
          recipeData: data.recipesection_set
        };
  
        console.log('newSiteConfig:', newSiteConfig);
        setSiteConfig(newSiteConfig);
      } catch (error) {
        console.error('Error fetching site_config:', error);
      }
    };
  
    fetchSiteConfig();
  }, [brandsiteUrl]);
  

  if (!siteConfig) {
    return <div>Loading...</div>;
  }
  return (
    <SiteConfigProvider config={siteConfig}>
      <Routes>
        <Route path='/' element={<SitePage />} />
        <Route path='recipes/:slug' element={<RecipePage />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />}/>
        {/* Other nested routes for SiteComponent */}
      </Routes>
    </SiteConfigProvider>
  );
}

function App() {
  const hostname = window.location.hostname;
  const isLocalhost = hostname.includes('localhost');
  let apiUrl;
  console.log('log env keys');
  console.log(process.env.REACT_APP_BACKEND)
  console.log(process.env.REACT_APP_PREVIEW_MODE)

  if (isLocalhost && process.env.REACT_APP_USE_LOCALHOST === 'true') {
    apiUrl = 'http://localhost:8000';
  } else {
    apiUrl = process.env.REACT_APP_BACKEND;
  }
  if (process.env.REACT_APP_PREVIEW_MODE === 'true'){
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<BrandsiteList apiUrl={apiUrl} />} />
        <Route path='/:siteName/*' element={<SiteComponent />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
  } else {
    return (
      <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<BrandsiteList apiUrl={apiUrl} />} /> */}
          <Route path='/*' element={<SiteComponent />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      </div>
    );
  }

}

export default App;