import React, { useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';
import './instagram.scss'


function InstagramSection() {
  const siteConfig = useContext(SiteConfigContext);
  const instagramBlock = siteConfig.components.find(
    component => component.block_type === 'InstagramBlock'
  );

  if (!instagramBlock) {
    return null; // Return something or render a placeholder if the block is not found
  }

  return (
    <div className="instagram-section">
      {instagramBlock.instagrampost_set.map((post, index) => (
        <InstagramPost key={index} post={post} />
      ))}
    </div>
  );
}

function InstagramPost({ post }) {
  const { image, alt_text, text, url } = post;

  function mediaLink(type, url, img_path, title) {
    switch (type) {
      case 'pinterest':
        return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${img_path}&description=${encodeURIComponent(
          title
        )}`;
      case 'facebook':
        return `https://www.facebook.com/share.php?u=${url}&title=${encodeURIComponent(
          title
        )}`;
      case 'twitter':
        return `http://twitter.com/share?text=${encodeURIComponent(
          title
        )}&url=${url}`;
      default:
        return '';
    }
  }

  return (
    <div className="instagram-post">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={alt_text} className="instagram-img" />
      </a>
      <div className='footer'>

      <div className="instagram-text">{text}</div>
      <div className="social-icons">
        {['pinterest', 'facebook', 'twitter'].map((media, i) => (
            <a
            href={mediaLink(media, url, image, text)}
            target="_blank"
            className="btn-social"
            key={i}
            rel="noopener noreferrer"
            >
            <i className={`fa fa-${media}`} />
          </a>
        ))}
      </div>
    </div>
        </div>
  );
}

export default InstagramSection;
