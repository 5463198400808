import React, {useEffect, useContext, useState} from 'react';
import {useParams} from "react-router-dom";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import FontAwesome from "../common/FontAwesome";
import { useNavigate } from "react-router-dom";
import { SiteConfigContext } from '../SiteConfigContext';


function RecipePage(props) {   
  const siteConfig = useContext(SiteConfigContext);
  let recipe = {}
  const { slug } = useParams();
  const block = siteConfig.components.find(component => component.block_type === 'RecipeBlock');
  const recipes = block.recipes;
  for (let i = 0; i < recipes.length; i++)
  {
    if (recipes[i].slug === slug){
      recipe = recipes[i];
    }
  }

  // const recipe.title = recipeName.replace(/-/g,' ');
  const recipeList = [];
  const url = window.location.href;
  
  // useEffect(() => {
    //   window.scrollTo(0, 0)
    // }, [])
    //pulling information from recipe.json
    // props.recipes.recipeSection.map((obj) => {
      //     return obj.sectionItems.map((items) =>{
        //       return recipeList.push(items)
        //     })       
        // })
        
        const recipeStyles = 'recipeDetail default';
        const a2c = recipe.a2c;
        const path = recipe.header_image;
        
        const headerStyle = {
          backgroundImage: "url(" + path + ")"
        }
        
        function mediaLink(type) {
          switch (type) {
            case 'pinterest':
              return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${path}&description=${encodeURIComponent(
                recipe.title,
                )}`;
                case 'facebook':
                  return `https://www.facebook.com/share.php?u=${url}&title=${encodeURIComponent(
                    recipe.title,
                    )}`;
                    case 'twitter':
                      return `http://twitter.com/share?text=${encodeURIComponent(
                        recipe.title,
                        )}&url=${url}`;
                        case 'envelope':
                          return `mailto:?subject=I think you will enjoy this recipe!&body=Here is a recipe that I know you'll love: ${encodeURIComponent(recipe.title)} - ${url}`;          
                          default:
                            break;
                          }
                        }
                        function printPage(){
                          window.print();
                          // trackShare('print');
                        }
                        function trackAddToCartEvent(){
                          window.gtag('event', 'addtocartclick', { 
                            product: recipe.title
                          });
                        }
                        function trackShare(e){
                          const type = e.currentTarget.getAttribute("data-value")
                          window.gtag('event', 'trackshare', { 
                            mediaType: type,
                            recipeTitle: recipe.title
                          });
                        }
                        // const history = useNavigate()
                        // const goBack = () => {
                          //   history.goBack()
                          // }
                          let recipeItem = recipe;
                          //display recipe detail page
                          return (
                            <div
                            className={recipeStyles}
                            >
        <div className="header">
          <div className="headerImage" style={headerStyle} >
          <div className='backButton'>
          {/* recipe_detail_ingredients_directions_header_text_color = ColorField(null=True, blank=True)
    recipe_detail_show_facebook = models.BooleanField(default=True)
    recipe_detail_show_twitter = models.BooleanField(default=True)
    recipe_detail_show_pinterest = models.BooleanField(default=True)
    recipe_detail_show_mail = models.BooleanField(default=True)
    recipe_detail_show_print = models.BooleanField(default=True) */}
          <Link to={"/"} style={{ color: block.recipe_detail_icon_color }} ><FontAwesome type='arrow-left'/></Link>
          </div>
          <img className="print-only" src={props.header_image} alt={recipe.title}/>
          </div>
        </div>
        <div className="subheader" style={{color: block.recipe_detail_title_color, backgroundColor: block.recipe_detail_header_background_color}}>
          
          <div className='subheaderContent'>
            <div className='social'>
              <h2 className='title' style={{color: block.recipe_detail_title_color}}>{recipe.title}</h2>
              <h3 style={{color: block.recipe_detail_subheader_color}}>{recipeItem.subheader}</h3>
                <div className='socialIcons'>
                  {['facebook','twitter','pinterest','envelope'].map((social, i) => {
                    return (
                      <a
                      href={mediaLink(social)}
                      onClick={trackShare}
                      data-value={social}
                      target="_blank"
                      className={`btn-social `+ social}
                      key={i}
                      rel="noopener noreferrer"
                      style={{ color: block.recipe_detail_icon_color, backgroundColor: block.recipe_detail_icon_background_color, borderRadius: "25px" }}
                      >
                            <FontAwesome type={social} />
                        </a>
                      );
                    })}
                  <div
                    onClick={printPage}
                    data-value='print'
                    target="_blank"
                    className="btn-social"
                    rel="noopener noreferrer"
                    style={{ color: block.recipe_detail_icon_color, backgroundColor: block.recipe_detail_icon_background_color, borderRadius: "25px" }}
                    >
                      <FontAwesome type='print' />
                  </div>
                </div>
            </div>
            <div className='times'>
            <div className='preptime'>
  {recipeItem.prep_time && (
    <>
      {block.recipe_detail_show_time_headers && (
        <p style={{ color: block.recipe_detail_time_headers_color }}>
          Prep Time
        </p>
      )}
      <p
        style={{
          color: block.recipe_detail_time_values_color,
        }}
        >
        <strong>{recipeItem.prep_time}</strong>
      </p>
    </>
  )}
</div>
<div className='totaltime'>
  {recipeItem.total_time && (
    <>
      {block.recipe_detail_show_time_headers && (
        <p style={{ color: block.recipe_detail_time_headers_color }}>
          Total Time
        </p>
      )}
      <p
        style={{
          color: block.recipe_detail_time_values_color,
        }}
        >
        <strong>{recipeItem.total_time}</strong>
      </p>
    </>
  )}
</div>
<div className='servings'>
  {recipeItem.servings && (
    <>
      {block.recipe_detail_show_time_headers && (
        <p style={{ color: block.recipe_detail_time_headers_color }}>
          Servings
        </p>
      )}
      <p
        style={{
          color: block.recipe_detail_time_values_color,
        }}
        >
        <strong>{recipeItem.servings}</strong>
      </p>
    </>
  )}
</div>

            </div>
          </div>
        </div>
        <div className="detail">
            <div className="ingredients">
                <h3 style={{color: block.recipe_detail_ingredients_directions_header_text_color}}>Ingredients</h3>
                {recipe.a2c && <div className='a2c-block'style={{backgroundColor: block.recipe_detail_a2c_block_background_color}}>
                  <p>Want to buy these ingredients?</p>
                  <button className="cart-btn" style={{ backgroundColor: block.recipe_detail_a2c_button_color}}>
                    <a 
                        href={a2c} 
                        onClick={trackAddToCartEvent} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >Add Items to Cart</a>
                  </button>
                </div>}
                {ReactHtmlParser(recipeItem.ingredients)}
            </div>
            <div className="directions">
                <h3 style={{color: block.recipe_detail_ingredients_directions_header_text_color}}>Directions</h3>
                {ReactHtmlParser(recipeItem.instructions)}
            </div>
        </div>
      </div>
    );

}

export default RecipePage;
