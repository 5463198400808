import React, { useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';

function Chatbot() {
  const siteConfig = useContext(SiteConfigContext);
  const block = siteConfig.components.find(
    (component) => component.block_type === 'ChatbotBlock'
  );

  function trackEvent(eventName) {
    console.log(`${eventName} event tracked`);
    window.gtag('event', eventName);
  }

  console.log(block);
  console.log('chatbot^');

  if (!block || !block.bot_type) {
    // Handle the case where block or block.bot_type is undefined or null
    return <div>Error: Chatbot configuration not found.</div>;
  }

  switch (block.bot_type) {
    case 'Marketing':
      return (
        <div onClick={() => trackEvent('Click_chatbot')}>
          <iframe
            src="https://webchat.botframework.com/embed/MarketingAg?s=jZTkkf-KZ-M.LiHrkDrqaThwYup10G0Y4DEkImyMMDQHGI32-N--fVE"
            style={{ minWidth: '400px', width: '50%', minHeight: '500px' }}
          ></iframe>
        </div>
      );
    case 'Test':
      return (
        <div onClick={() => trackEvent('Click_chatbot')}>
          <iframe src='https://webchat.botframework.com/embed/Rockstarequity?s=sxXCDIHaLbU.JeYyZxABmgGXtvIMVeKLrs_1sBPh3xBlqE61a-k7ClY'  style={{ minWidth: '400px', width: '50%', minHeight: '500px'}}></iframe>
        </div>
      );
    default:
      return <></>;
  }
}

export default Chatbot;
