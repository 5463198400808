import React, { useEffect, useState, useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';
import './progress.scss';
import useConvertImageToText from '../imageToText';

function convertCentsToDollars(centsString) {
  // Remove commas from the string
  var stringWithoutCommas = centsString.replace(/,/g, '');
  
  // Convert the string to a number and divide by 100 to get dollars
  var dollars = parseFloat(stringWithoutCommas) / 100;
  
  // Format the dollars with a dollar sign
  var formattedDollars = '$' + dollars.toFixed(2);
  
  return formattedDollars;
}

function trackViewCoupons() {
  console.log('tracked viewCoupons event')
  window.gtag('event', 'Click_publix_coupons', {});
}

function ProgressBlock({ user }) {
  const [text, setText] = useState("");
  const siteConfig = useContext(SiteConfigContext);
  const convertImageToText = useConvertImageToText();
  let rewards = parseInt(siteConfig.rewards_requirement);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Call the function returned by the hook, passing the phoneNumber as an argument
        const convertedText = await convertImageToText(user);
        setText(convertCentsToDollars(convertedText));
      } catch (error) {
        // Handle error
        console.error('Error fetching converted text', error);
      }
    };
    fetchData();
  }, [user]);

  let textnumber = text.replace('$', '')
  let isRewardGreater = rewards > parseFloat(textnumber);
  let isRewardLess = rewards <= parseFloat(textnumber)
  if (textnumber === 'NaN') {
    setText('$0');
  }
  

  return (
    <div className='offer-container'>
      <div className='heading'>You have spent {text}</div>
      {isRewardGreater && <div className='earn-block'>Earn your reward at ${siteConfig.rewards_requirement}</div>}
      {isRewardLess && <div className='earn-block'>Your reward has been clipped to your Publix wallet</div>}
      <div className='clipped-rewards-block'>
        <a
          href="https://www.publix.com/savings/digital-coupons?mods=Clipped%20Coupons"
          onClick={trackViewCoupons}
          target="_blank" rel="noopener noreferrer"
        >
          Click here
        </a>{" "}
        to see clipped rewards in your Publix digital wallet.
      </div>
    </div>
  );
}

export default ProgressBlock;