import React, { useContext } from 'react';
import './logoGarden.scss';
import { SiteConfigContext } from '../SiteConfigContext';

function GardenImages() {
  const siteConfig = useContext(SiteConfigContext);
  const gardenBlock = siteConfig.components.find(component => component.block_type === 'LogoGardenBlock');

  const gardenStyle = {
    backgroundColor: gardenBlock.color_background,
  };

  const imgStyle = {
    height: gardenBlock.height,
  };

  return (
    <div className='garden' style={gardenStyle}>
      {gardenBlock.gardenimage_set.map((item, i) => (
        <a href={item.link || ''} target={item.link ? '_blank' : ''} rel="noopener noreferrer" key={i}>
          <img
            className={`gardenImg`}
            src={item.image}
            alt={item.alt_text || ''}
            style={imgStyle}
          />
        </a>
      ))}
    </div>
  );
}

export default GardenImages;
