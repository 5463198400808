import { ReactComponent as ErrorSvg} from './circle-exclamation.svg'
import './ErrorModal.scss';
import React, { useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';


const ErrorModal = ({ show, handleClose }) => {
    const siteConfig = useContext(SiteConfigContext);
    const block = siteConfig.components.find(component => component.block_type === 'LoyaltyBlock');

  function trackRegistrationEvent (){
    console.log('registration click event tracked')
    window.gtag('event', 'retailer_registration_click', {  });
  }

  return (
    <div
      className={`modal fade ${show ? 'show' : ''} error-modal`}
      style={{ display: show ? 'block' : 'none'}}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
      <div className={`modal-content error-modal-content`}>
      <ErrorSvg className="error-icon" />
          <div>
              <h3 className="popup-header-text">ENROLLMENT FAILED</h3>
              <p className='popup-body-text'>
              Your enrollment has failed. This may be because you don’t have a Publix account yet.
              Create a Publix Account <a href="https://www.publix.com/myaccount/register?clubpublix=true&pbx_cc1=3303567" 
              target="_blank" rel="noopener noreferrer" onClick={trackRegistrationEvent}> here </a>
              and try enrolling again. If you keep having issues with enrollment, 
              contact us at 855-378-3791 
              or email us at loyaltysupport@inmar.com
              </p>
            <button type="button" className="close-button" onClick={handleClose}>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;