import React, { useState, useContext } from 'react';
import './ageGate.scss';
import { SiteConfigContext } from '../../SiteConfigContext';

function NumberAgeGate({ ageGate, updateAgeGate, title, instructions, minimum_age }) {
  const [age, setAge] = useState('');
  const [ageError, setAgeError] = useState('');
  const siteConfig = useContext(SiteConfigContext);
  const block = siteConfig.components.find(component => component.block_type === 'AgeGateBlock');

  const isValidAge = () => {
    const numericAge = parseInt(age, 10);

    if (isNaN(numericAge)) {
      return false;
    }

    return true;
  };

  const handleFormAgeSubmit = (event) => {
    event.preventDefault();
    if (isValidAge()) {
      if (parseInt(age, 10) >= minimum_age) {
        updateAgeGate(true);
      } else {
        setAgeError(`You must be ${minimum_age} years or older.`);
      }
    }
  };

  return (
    (
      <div className={`age-gate-container`}>
        <div className={`age-gate`}>
          <h2>{title}</h2>
          <form onSubmit={handleFormAgeSubmit}>
            <div className="form-group">
              <label>{instructions}</label>
              <input
                placeholder="Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                style={{ maxWidth: '30%', margin: '10px' }}
              />
            </div>
            {ageError && <p className="error-message">{ageError}</p>}
            <button type="submit" style={{backgroundColor: block.button_color}}>{block.button_text}</button>
          </form>
        </div>
      </div>
    )
  );
}

export default NumberAgeGate;
