import React, {useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { SiteConfigContext } from '../SiteConfigContext';

import './hero.scss';

// TODO: re-enable carousel

function Hero() {
  const imgPath = "";
  //props fetch data from hero.json
  // const brand = process.env.REACT_APP_BRAND_NAME;
  const siteConfig = useContext(SiteConfigContext);
  // Make conditional if local file or API
  // const imgPath = '/images/' + siteConfig.siteName + '/' + siteConfig.brandName + '/';
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  
  // const gardenBG = siteConfig.hero.garden[0].background.hasBG === 'true' ? imgPath + siteConfig.hero.garden[0].background.image : '';
  // const gardenStyle = {
  //   backgroundImage: "url(" + gardenBG + ")"
  // };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  //switch to hero mobile image if screen is less than 768px width

  function StaticHero(){
    const hero = siteConfig.components.find(component => component.block_type === 'HeaderBlock');
    // const imgPath = process.env.REACT_APP_BACKEND + (!isMobile ? siteConfig.header_set[0].header_image : siteConfig.header_image_mobile)
    console.log('hero')
    console.log(hero)
    return(
      <img
        className={`heroImg`}
        // src={process.env.REACT_APP_BACKEND+hero.header_image}
        src={hero.header_image}
        alt={hero.header_image_alt_text}
      />
    )
  }
  function HeroCarousel() {
    const hero = siteConfig.components.find(component => component.block_type === 'HeaderBlock').header_set[0];
    const heroCarousel = siteConfig.components.find(component => component.block_type === 'HeaderBlock').header_set;
    return (
      <Carousel
        autoPlay={true}
        interval={5000}
        infiniteLoop={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        swipeable={true}
        onClickItem={anchorItem}
      >
        {heroCarousel.map((item, i) => {
          return(
            <a href={item.anchor} key={i}>
              <img
                className={`heroImg`}
                src={item.header_image}
                alt={item.header_image_alt_text}
              />
            </a>
          )
        })}
      </Carousel>
    );
  }
  function anchorItem(i,child){
    if(child.props.href){
      window.location.href = child.props.href;
    }
  }

  const hero = siteConfig.components.find(component => component.block_type === 'HeaderBlock');
  return (
    
    <div className='header'>
      <div className="hero">
        { hero.header_set.length > 0 && <HeroCarousel/> }
        { hero.header_set.length == 0 && <StaticHero/> }
      </div>
    </div>
  );
}
export default Hero;
