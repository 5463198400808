import React, { useContext } from 'react';

import { Modal } from 'react-bootstrap';
// import './qualifyingProducts.scss';
import { SiteConfigContext } from '../SiteConfigContext';

function QualifyingProductsBlock({ show, handleClose }) {
  const siteConfig = useContext(SiteConfigContext);
  const block = siteConfig.components.find(component => component.block_type === 'QualifyingProductsBlock');


  const heading = block.qualifying_products_popup_heading

  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet" />
      <Modal show={show} onHide={handleClose} scrollable className="qualifying-modal">
        <Modal.Header>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {/* {brandsAndProducts.map((brandObj, index) => (
            <div key={index} className="brand-list">
              <h2>{brandObj.header}</h2>
              <div className="product-list"> */}
                {/* {brandObj.items.map((product, productIndex) => (
                  <div>
                    <div key={productIndex}>{product.name}</div>
                    <div className="upc">{`UPC: ${product.upc}`}</div>
                  </div>
                ))} */}<div dangerouslySetInnerHTML={{ __html: block.qualifying_products_popup_list }}/>
                
                {/* {index !== brandsAndProducts.length - 1 && <div className="divider"></div>} */}
                {/* </div> */}
              {/* </div> */}
              {/* </div> */}
          {/* ) */}
          {/* ) */}
          {/* } */}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="close-button" onClick={handleClose}>
            CLOSE
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )};

export default QualifyingProductsBlock;
