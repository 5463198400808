import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { SET_OFFERS } from '../redux/reducers/offer';
import MetaDataBlock from '../metadata';
import LoginModal from '../loginModal';
import Offers from '../offers';
import RegistrationBlock from '../registration';
import Hero from '../hero';
import Cart from '../cart';
import Recipes from '../recipes';
import Chatbot from '../chatbot'
import Footer from '../footer';
import Spinner from '../common/Spinner';
import ErrorToast from '../common/ErrorToast';
import LogoGardenImages from '../logoGarden';
import TikTokSection from '../tiktok';
import InstagramSection from '../instagram';
import FreeText from '../freeText';
import ContactBlock from '../contact';
import LoadTesseract from '../loadTesseract';
import LoyaltyBlock from '../loyalty';
import InformationBanner from '../informationBanner';
import FAQBlock from '../faq';
import ProgressBlock from '../progress';
import DateAgeGate from '../ageGate/date';
import NumberAgeGate from '../ageGate/number';
import CheckboxAgeGate from '../ageGate/checkbox';
import OfferBlock from '../offerBlock'

import '../App.css';
import { SiteConfigContext } from '../SiteConfigContext';

function SitePage() {
  const siteConfig = useContext(SiteConfigContext);
  const ageGate = siteConfig.components.find((component) => component.block_type === 'AgeGateBlock');
  const metaData = siteConfig.components.find((component) => component.block_type === 'MetaDataBlock');
  const [ageGateLocked, setAgeGateLocked] = useState(ageGate ? true : false);
  const [isLoading, setIsLoading] = useState(false)

  const hostname = window.location.hostname;

  const showLogin = useSelector((state) => state.offer.show_login);


  function ModalBackdrop() {
    return showLogin ? <div className="modal-backdrop fade show"></div> : '';
  }

  const appClasses = `App ${showLogin ? 'modal-open' : ''}`;

  const updateAgeGate = () => {
    setAgeGateLocked(!ageGate);
  };

  const updateLoadingState = () => {
    setIsLoading(!isLoading)
  }

  const header = siteConfig.components.find((component) => component.block_type === 'HeaderBlock');
  const footer = siteConfig.components.find((component) => component.block_type === 'FooterBlock');
  if (isLoading) {
    return <Spinner />;
  } else if (new Date(siteConfig.live_date) > new Date()) {
    return (
      <>
        {metaData && <MetaDataBlock />}
        {header && <Hero />}
        {!header && 'Coming Soon'}
        {footer && <Footer />}
      </>
    );
  } else if (ageGateLocked) {
    switch (ageGate.input_type) {
      case 'Number':
        return (
          <>
            {metaData && <MetaDataBlock />}
            {header && <Hero />}
            <NumberAgeGate
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
            />
            {footer && <Footer />}
          </>
        );
      case 'Date':
        return (
          <>
            {metaData && <MetaDataBlock />}
            {header && <Hero />}
            <DateAgeGate
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
            />
            {footer && <Footer />}
          </>
        );
      case 'Boolean':
        return (
          <>
            {header && <Hero />}
            <CheckboxAgeGate
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
            />
            {footer && <Footer />}
          </>
        );
      default:
        return null;
    }
  } else {
    const renderComponent = (component) => {
      switch (component.block_type) {
        case 'ContactBlock':
          return <ContactBlock />;
        case 'LoyaltyBlock':
          return (
            <>
              <LoadTesseract />
              <LoyaltyBlock />
            </>
          );
        case 'FAQBlock':
          return <FAQBlock />;
        case 'LogoGardenBlock':
          return <LogoGardenImages />;
        case 'MetaDataBlock':
          return <MetaDataBlock />;
        case 'QualifyingProductsBlock':
          return <InformationBanner />;
        case 'TikTokBlock':
          return <TikTokSection />;
        case 'HeaderBlock':
          return <Hero />;
        case 'OfferBlock':
          return (
            <OfferBlock updateLoadingState={updateLoadingState} />
            // <div>
            //   <RegistrationBlock offers={offers} />
            //   <Offers offers={offers} />
            // </div>
          );
        case 'AddToCartBlock':
          return <Cart />;
        case 'RecipeBlock':
          return <Recipes />;
        case 'InstagramBlock':
          return <InstagramSection />;
        case 'FooterBlock':
          return <Footer />;
        case 'FreeTextBlock':
          return <FreeText />;
        case 'ChatbotBlock':
          return <Chatbot />;
        default:
          return null;
      }
    };

    return (
      <div
        className={('App ', appClasses)}
        id="App"
        style={{
          backgroundColor: siteConfig.colorBackground,
          color: siteConfig.colorText,
        }}
      >
        <LoginModal />
        <div className={`App-content container`} id="App-content">
          <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat"></div>
          <div>
            {siteConfig.components.map((component, index) => (
              <div key={index}>{renderComponent(component)}</div>
            ))}
          </div>
          <ErrorToast />
        </div>
        <ModalBackdrop />
      </div>
    );
  }
}

export default SitePage;
