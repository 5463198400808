import React, { useContext } from 'react';
import { ReactComponent as Facebook } from './Facebook.svg';
import { ReactComponent as Instagram } from './Instagram.svg';
import { ReactComponent as LinkedIn } from './Linkedin.svg';
import { ReactComponent as Twitter } from './Twitter.svg';
import { SiteConfigContext } from '../SiteConfigContext';
import { Link } from 'react-router-dom';

import './footer.scss';

function Footer() {
  const siteConfig = useContext(SiteConfigContext);

  // Find the FooterBlock in the components array
  const block = siteConfig.components.find(component => component.block_type === 'FooterBlock');

  // Extract data from the FooterBlock
  const {
    link1_text,
    link1_url,
    link2_text,
    link2_url,
    link3_text,
    link3_url,
    facebook_link,
    instagram_link,
    linkedin_link,
    twitter_link,
    color_text,           // Color for text in the footer
    color_background,     // Background color of the footer
    color_icons,          // Color for social media icons
  } = block;

  function trackEvent(eventName) {
    console.log(`${eventName} event tracked`);
    window.gtag('event', eventName);
  }

  // Apply the extracted color values to styles
  const footerStyle = {
    color: color_text,
    backgroundColor: color_background,
  };

  const svgStyle = {
    fill: color_icons,
  };

  return (
    <div className="footer-content" style={footerStyle}>
      <div className="left-right-sections">
        {/* Left Section */}
        <div className="left-section">
          <div className="links-container">
            {/* Rendering link1 */}
            <div className="ca-privacy-rights">
            <Link to={`/${siteConfig.brandsite_name}/privacy-policy`}
              style={footerStyle}
                role="button"
                tabIndex="0"
                // href={link1_url}
                onClick={() => trackEvent('Click_terms')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link1_text}
              </Link>
            </div>
            {/* Rendering link2 */}
            <div className="ca-privacy-rights">
              <Link to={`/${siteConfig.brandsite_name}/privacy-policy`}
              style={footerStyle}
                role="button"
                tabIndex="0"
                // href={link2_url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackEvent('Click_GMI_privacy_policy')}
              >
                {link2_text}
              </Link>
            </div>
            {/* Rendering link3 */}
            <div className="ca-privacy-rights">
            <Link to={`/${siteConfig.brandsite_name}/privacy-policy`}
              style={footerStyle}
                role="button"
                // href={link3_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link3_text}
              </Link>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="right-section">
          {/* Rendering social media icons */}
          {facebook_link && <div className="icon">
          <a href={facebook_link} onClick={() => trackEvent('Click_social_facebook')} target="_blank" rel="noopener noreferrer">
              <Facebook style={svgStyle} width="24px" height="24px" />
            </a>
          </div>}
          {instagram_link && <div className="icon">
            <a href={instagram_link} onClick={() => trackEvent('Click_social_instagram')} target="_blank" rel="noopener noreferrer">
              <Instagram style={svgStyle} width="24px" height="24px" />
            </a>
          </div>}
          {linkedin_link && <div className="icon">
            <a href={linkedin_link} onClick={() => trackEvent('Click_social_linkedin')} target="_blank" rel="noopener noreferrer">
              <LinkedIn style={svgStyle} width="24px" height="24px" />
            </a>
          </div>}
          {twitter_link && <div className="icon">
            <a href={twitter_link} onClick={() => trackEvent('Click_social_twitter')} target="_blank" rel="noopener noreferrer">
              <Twitter style={svgStyle} width="24px" height="24px" />
            </a>
          </div>}
        </div>
      </div>
      <div className="trademark-info">
        {/* ©2023 Conagra Brands, Inc. All Rights Reserved. */}
      </div>
    </div>
  );
}

export default Footer;
